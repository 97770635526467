import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';

const Location = styled.iframe`
  border: 0;
  height: 500px;
  width: 100%;
  z-index: 1;

  @media (min-width: ${(props) => props.theme.breakpoint}) {
    height: 500px;
  }
`;

const Address = styled.address`
  flex: 1;
  font-size: 3rem;
  font-weight: bolder;
  margin: 4rem;
  text-align: center;
`;

export default function Contact() {
  return (
    <Layout>
      <Location
        title="lodge-location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2982.0104663740904!2d-70.93074969999996!3d41.6339052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e4e3f2ccf98e95%3A0xb2894fbb1bcf6b1d!2s435+County+St%2C+New+Bedford%2C+MA+02740!5e0!3m2!1sen!2sus!4v1432577492184"
        frameBorder="0"
        id="map-canvas"
        height="500px"
      />
      <Address>435 County Street, New Bedford, MA 02740</Address>
    </Layout>
  );
}
